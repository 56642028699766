// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bX_kz";
export var caseStudyBackground__lineColor = "bX_kq";
export var caseStudyHead__imageWrapper = "bX_kn";
export var caseStudyProjectsSection = "bX_kB";
export var caseStudyQuote__bgRing = "bX_kw";
export var caseStudySolution__ring = "bX_kt";
export var caseStudySolution__ringThree = "bX_md";
export var caseStudySolution__ringTwo = "bX_kv";
export var caseStudyVideo__ring = "bX_kF";
export var caseStudy__bgDark = "bX_km";
export var caseStudy__bgLight = "bX_kl";
export var caseStudy__bgLightGray = "bX_mH";